import React from "react"
import Navbar from "./Navbar"
import Head from "./Head"
import { Link } from "gatsby"
import dot from "../images/dot.png"

import HandleScroll from "./HandleScroll"

const Layout = ({ key, to, thumbnail, title, date }) => {
  return (
    <Link key={key} to={to}>
      <div className="post">
        <img className="icon" src={thumbnail || dot} alt="" />
        <div className="text">
          <h2>{title}</h2>
          <span>{date}</span>
        </div>
      </div>
    </Link>
  )
}

export default Layout
