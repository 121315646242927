import React from "react"
import Layout from "../components/Layout"
import ContentLink from "../components/ContentLink"
import { graphql } from "gatsby"
import R from "ramda"

const articles = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Layout>
      <h1>Articles</h1>
      <div className="posts">
        {edges.map(({ node }, index) => (
          <ContentLink
            key={node.id}
            to={`/articles/${node.fields.slug}`}
            thumbnail={R.path(
              ["frontmatter", "thumbnail", "childImageSharp", "fixed", "src"],
              node
            )}
            title={node.frontmatter.title}
            date={node.frontmatter.date}
          />
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(
      filter: {
        frontmatter: { published: { eq: true } }
        fileAbsolutePath: { regex: "/posts/" }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            thumbnail {
              childImageSharp {
                fixed(width: 50) {
                  src
                }
              }
            }
          }
          fields {
            slug
          }
          id
        }
      }
    }
  }
`

export default articles
